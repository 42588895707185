import React from "react";
import "antd/dist/antd.css";
import Footer from "../landingPage/footer";
import CookieConsentBanner from "../cookieConsentBanner";
import { LoadingOutlined } from "@ant-design/icons";
import NavBar from "../landingPage/header/navbar";
import {
  Input,
  Button,
  Row,
  Col,
  message,
  Layout,
  List,
  Select,
  Avatar,
  Form,
  Modal,
} from "antd";
import axios from "axios";
import "./index.scss";
import { Helmet } from "react-helmet";
import MetaTagContents from "../../MetaTagContents";
import TextConstants from "../../TextConstants";

const { Content } = Layout;
const { TextArea } = Input;

class ContactUsForm extends React.Component {
  formRef = React.createRef();

  state = {
    button_loader: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementById("help-heading");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });
  }

  render() {
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };

    const onFinish = (values) => {
      console.log(values);
      this.setState({
        button_loader: true,
      });
      let data = {};
      data.first_name = values.firstName;
      data.last_name = values.lastName;
      data.email = values.email;

      if (values.company != undefined) {
        data.company = values.company;
      }

      if (values.message != undefined) {
        data.message = values.message;
      }

      if (values.messageType != undefined) {
        data.messageType = values.messageType;
      }

      let pageOrigin = new URLSearchParams(window.location.search).get("origin");
      if (pageOrigin == 'portal') {
        data.origin = 'portal';
      }

      console.log(JSON.stringify(data));

      axios
        .post(
          "https://x1wtkvgpdd.execute-api.us-west-2.amazonaws.com/production",
          JSON.stringify(data)
        )
        .then((res) => {
          this.formRef.current.resetFields();
          this.setState({
            button_loader: false,
          });
          Modal.success({
            content:
              "Thanks for showing your interest. We will get in touch with you very soon.",
          });
        })
        .catch((error) => {
          this.setState({
            button_loader: false,
          });
          message.error("Error in sending message.");
        });
    };

    const { title, text, link_text, options } = TextConstants.CONTACT_US_FORM;
    const { name, content } = MetaTagContents.CONTACT_US_FORM;
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{MetaTagContents.CONTACT_US_FORM.title}</title>
          <link rel="canonical" href="https://all3d.ai/contact-us" />
          <meta name={name} content={content} />
        </Helmet>

        <div>
          <NavBar />
          <Layout className="margin-above-footer">
            <Content>
              <CookieConsentBanner />
              <Row className="reverse">
                <Row className="container-row">
                  <div className="help-form-container">
                    <Col
                      xxl={8}
                      lg={8}
                      offset={0}
                      className="row-large-screen left-margin"
                    >
                      <h1
                        style={{ marginTop: "120px" }}
                        className="help-header"
                      >
                        {title}
                      </h1>

                      <div className="contact-help-text">{text}</div>
                      <div
                        style={{ marginTop: "32px", display: "flex" }}
                        className="row-large-screen"
                      >
                        <a
                          href={"https://app.all3d.ai/"}
                          className="sign-in-ref"
                        >
                          {link_text}
                        </a>
                        <div>
                          <img
                            src="arrow-right.svg"
                            style={{ marginLeft: "15px" }}
                            alt="Sign In"
                          />
                        </div>
                      </div>
                    </Col>
                    <div className="row-large-screen space-columns"></div>
                    <Col xxl={8} lg={8} className="form-column">
                      <div
                        style={{ height: 80 }}
                        className="row-large-screen"
                      ></div>
                      <div>
                        <Form
                          {...layout}
                          name="nest-messages"
                          onFinish={onFinish}
                          className="form-margin"
                          ref={this.formRef}
                        >
                          <Form.Item>
                            <Form.Item
                              name="firstName"
                              style={{
                                display: "inline-block",
                                width: "calc(50% - 8px)",
                                marginBottom: "0px",
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter first name!",
                                },
                              ]}
                            >
                              <Input
                                className="name-placeholder"
                                placeholder="First Name"
                              />
                            </Form.Item>
                            <Form.Item
                              name="lastName"
                              style={{
                                display: "inline-block",
                                width: "calc(50% - 8px)",
                                marginBottom: "0px",
                                marginLeft: "16px",
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter last name!",
                                },
                              ]}
                            >
                              <Input
                                className="name-placeholder"
                                placeholder="Last Name"
                              />
                            </Form.Item>
                          </Form.Item>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please enter a valid email!",
                                type: "email",
                              },
                            ]}
                          >
                            <Input
                              className="other-placeholder"
                              placeholder="Email Address"
                            />
                          </Form.Item>
                          <Form.Item name="company">
                            <Input
                              className="other-placeholder"
                              placeholder="Company Name"
                            />
                          </Form.Item>
                          <Form.Item name="messageType" id="select-message">
                            <Select placeholder="Message Type">
                              {options.map((x) => (
                                <Select.Option value={x}>{x}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item name="message">
                            <Input.TextArea
                              className="text-area-placeholder"
                              placeholder="What do you need help with?"
                              rows={6}
                            />
                          </Form.Item>
                          <Form.Item
                            wrapperCol={{ ...layout.wrapperCol, offset: 8 }}
                            className="button-margin-top"
                          >
                            <div>
                              <Button
                                disabled={this.state.button_loader}
                                type="primary"
                                htmlType="submit"
                                id="submit-btn"
                              >
                                <span className="submit-btn-text">
                                  Submit{" "}
                                  {this.state.button_loader ? (
                                    <LoadingOutlined />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Button>
                            </div>
                          </Form.Item>
                        </Form>
                      </div>
                    </Col>
                  </div>
                </Row>
                <Row className="container-row row-small-screen">
                  <div className="help-heading-container">
                    <Col>
                      <h2
                        style={{ marginTop: "120px" }}
                        className="help-header"
                        id="help-heading"
                      >
                        {title}
                      </h2>
                      <div className="contact-help-text">{text}</div>
                    </Col>
                  </div>
                </Row>
              </Row>
            </Content>
          </Layout>
          <div className="abs-btm">
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactUsForm;
